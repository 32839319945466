.login-container[data-v-37dfd6fc] {
  width: 100%;
}
.login-bg[data-v-37dfd6fc] {
  position: absolute;
  height: 50vh;
  width: 100%;
  background-color: #00b38a;
}
.login-display[data-v-37dfd6fc] {
  position: absolute;
  max-width: 433px;
  height: 448px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  color: #505050;
  background-color: white;
  border-radius: 1px;
  font-size: 14px;
  border: #dce1e6 solid 1px;
  text-align: center;
  -webkit-box-shadow: 2px 3px 12px rgba(172, 172, 172, 0.5);
          box-shadow: 2px 3px 12px rgba(172, 172, 172, 0.5);
}
.login-logo .img[data-v-37dfd6fc] {
  width: 184px;
  text-align: center;
  margin: auto;
  margin-top: 20px;
}
.login-logo .img img[data-v-37dfd6fc] {
    width: 100%;
}
.login-title[data-v-37dfd6fc] {
  position: relative;
}
.login-title span[data-v-37dfd6fc] {
    background-color: #fff;
    position: relative;
}
.login-title span[data-v-37dfd6fc]::before, .login-title span[data-v-37dfd6fc]::after {
      content: '';
      position: absolute;
      width: 60px;
      height: 1px;
      background-color: #999999;
      vertical-align: bottom;
      top: 0;
      bottom: 0;
      margin: auto;
}
.login-title span[data-v-37dfd6fc]::before {
      left: -63px;
}
.login-title span[data-v-37dfd6fc]::after {
      right: -62px;
}
