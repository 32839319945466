.login-input {
  position: relative;
  width: 300px;
  margin: auto;
  border-bottom: 1px solid #ddd;
  margin-top: 28px;
}
.login-input .login-svg-left, .login-input .login-svg-right {
    position: absolute;
    z-index: 100;
    padding-top: 1px;
}
.login-input .login-svg-left .svg-icon, .login-input .login-svg-right .svg-icon {
      width: 16px;
      height: 16px;
}
.login-input .login-svg-left {
    left: 3px;
}
.login-input .login-svg-right {
    right: 11px;
}
.login-input .el-input__inner {
    border: none;
    padding: 0 25px;
}
.login-btn {
  width: 300px;
  margin: auto;
  margin-top: 25px;
}
.login-btn button {
    background-color: #00b38a !important;
    border: none;
    color: #fff !important;
}
.login-btn button:hover {
      color: #fff;
      background-color: #00b38a !important;
}
.login-btn button:active {
      background-color: rgba(0, 179, 138, 0.8) !important;
}
